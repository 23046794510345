<template>
  <div class="operation-log-content">
    <comp-part title="操作日志"></comp-part>
    <table-comp
      class="content-ruleForm"
      :table-opts="tableOpts"
      :data="tableData"
      :page="currentPage"
      :total="totalPage"
      :page-size="pageSize"
      :show-number="true"
      :handle-opts="handleOpts"
      @handleSizeChange="sizeChange"
      @handleCurrentChange="pageChange"
    >
    </table-comp>
  </div>
</template>

<script>
import TableComp from '@/components/Table/Table'
import CompPart from '@/components/Part'
import { getOplogInfo } from '@/service/module/common'
export default {
  components: {
    TableComp,
    CompPart
  },
  data () {
    return {
      tableOpts: [ // 列表表头设置
        {
          label: '操作时间',
          prop: 'createAt',
          width: '180px',
          render: (h, params) => {
            var moment = require('moment')
            const createTime = moment.unix(params.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')

            const createTimeText = '-'
            if (!createTime) {
              return h('span', {}, createTimeText)
            } else {
              return h('span', {}, createTime)
            }
          }
        },
        {
          label: '操作模块',
          prop: 'moduleName',
          width: '131.4px',
          render: (h, params) => {
            const moduleName = params.row.moduleName
            const moduleNameText = '-'
            if (!moduleName) {
              return h('span', {}, moduleNameText)
            } else {
              return h('span', {}, moduleName)
            }
          }
        },
        {
          label: '操作类型',
          prop: 'actionTypeName',
          width: '131.4px',
          render: (h, params) => {
            const actionTypeName = params.row.actionTypeName
            const actionTypeNameText = '-'
            if (!actionTypeName) {
              return h('span', {}, actionTypeNameText)
            } else {
              return h('span', {}, actionTypeName)
            }
          }
        },
        {
          label: '操作内容',
          prop: 'description',
          render: (h, params) => {
            const description = params.row.description
            const descriptionText = '-'
            if (!description) {
              return h('span', {}, descriptionText)
            } else {
              return h('span', {}, description)
            }
          }
        },
        {
          label: '操作人',
          prop: 'operator',
          width: '131.4px',
          render: (h, params) => {
            const operator = params.row.operator
            const operatorText = '-'
            if (!operator) {
              return h('span', {}, operatorText)
            } else {
              return h('span', {}, operator)
            }
          }
        },
        {
          label: '用户名',
          prop: 'username',
          width: '131.4px',
          render: (h, params) => {
            const userName = params.row.username
            const userNameText = '-'
            if (!userName) {
              return h('span', {}, userNameText)
            } else {
              return h('span', {}, userName)
            }
          }
        }
      ],
      tableData: [],
      handleOpts: {
      },
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20 // 每页条数
    }
  },
  created () {
    this.getOplogInfo(1, 20)
  },
  methods: {
    getOplogInfo (pageNum, pageSize) {
      getOplogInfo({
        pageNum: pageNum,
        pageSize: pageSize
      }).then(res => {
        if (res) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
          this.currentPage = res.data.currentPage
          this.pageSize = res.data.pageSize
        }
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getOplogInfo(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getOplogInfo(val, this.pageSize)
    }
  }
}
</script>

<style scoped>
.operation-log-content {
  margin:  0 20px;
}
</style>
